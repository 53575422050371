import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Articles from './pages/Articles';
import ArticleDetail from './pages/ArticleDetail';
import Contact from './pages/Contact';
import theme from './theme';
import CardPage from './pages/CardPage';
import TwitterFeed from './components/TwitterFeed';
import TweetEmbed from './components/TweetEmbed';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/articles" element={<Articles />} /> */}
          <Route path="/atrocities" element={<TwitterFeed />} />
          <Route path="/articles" element={<CardPage />} />
          {/* <Route path="/articles/:id" element={<ArticleDetail />} /> */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
