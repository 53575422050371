import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import TweetEmbed from './TweetEmbed';

const TwitterFeed: React.FC = () => {
    return (
        <div className="twitter-feed">
            <TweetEmbed tweetUrl="https://twitter.com/me_sourish_/status/1822299677309497826?ref_src=twsrc%5Etfw" />
            <TweetEmbed tweetUrl="https://twitter.com/KreatelyMedia/status/1822576665089757412?ref_src=twsrc%5Etfw" />
            <TweetEmbed tweetUrl="https://twitter.com/Salwan_Momika1/status/1822611579826851982" />

        </div>
    );
};

export default TwitterFeed;
