import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import AutoScrollImages from '../components/AutoScrollImages';
import CarouselComponent from '../components/CarouselComponent';

const Home: React.FC = () => {
    return (
      <Container>
        {/* Image Scroller */}
        <Box sx={{ marginBottom: 2 }}>
          {/* <AutoScrollImages /> */}
          <CarouselComponent/>
        </Box>
  
        {/* Intent Paragraph */}
        <Box
          sx={{
            marginTop: 2,
            padding: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1">
            This website is dedicated to supporting the Bangladeshi Hindu community by providing timely news, resources, and guidance. Our goal is to keep you informed about important developments, offer practical support, and ensure your safety in challenging times. We are committed to fostering a supportive environment and empowering you with the information and tools you need.
          </Typography>
        </Box>
      </Container>
    );
  };
  
  export default Home;