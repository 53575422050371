import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px 0',
        marginTop: 'auto',
      }}
    >
      <Typography variant="body2">
        © 2024 Our Blog. All rights reserved.
      </Typography>
      <Typography variant="body2">
        Email: contact@EastBengali.com 
      </Typography>
    </Box>
  );
};

export default Footer;
