// CardComponent.tsx
import React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

interface CardComponentProps {
    heading: string;
    image: string;
    description: string;
    link: string;
}

const CardComponent: React.FC<CardComponentProps> = ({ heading, image, description, link }) => {
    return (
        <Card sx={{ width: '100%', maxWidth: 600, marginBottom: 2 }}>
          <CardActionArea >
            {/* <CardActionArea component={Link} to={link}> */}
                <CardMedia
                    component="img"
                    image={image}
                    alt={heading}
                    sx={{
                        height: 140, // Fixed height for the image
                        objectFit: 'contain', // Ensures the entire image fits within the CardMedia while maintaining aspect ratio
                        width: '100%' // Ensures the image takes the full width of the Card
                    }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CardComponent;
