import React from 'react';
import { Box, Typography } from '@mui/material';

const Contact: React.FC = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h1">Contact Us</Typography>
      <Typography variant="body1">Email: contact@EastBengali.com </Typography>
      <Typography variant="body1">Phone: </Typography>
    </Box>
  );
};

export default Contact;
