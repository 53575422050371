import React, { useEffect } from 'react';

interface TweetEmbedProps {
  tweetUrl: string;
}

const TweetEmbed: React.FC<TweetEmbedProps> = ({ tweetUrl }) => {
  useEffect(() => {
    // Ensure the Twitter script is loaded
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <blockquote className="twitter-tweet">
      <a href={tweetUrl}></a>
    </blockquote>
  );
};

export default TweetEmbed;
