// CardPage.tsx
import React from 'react';

// Import images
import Image1 from '../assets/hide.jpg';
import Image2 from '../assets/imposter.jpg';
import Image3 from '../assets/poster3.jpg';
import CardComponent from '../components/CardComponent';

interface CardData {
    heading: string;
    image: string;
    description: string;
    link: string;
}

const CardPage: React.FC = () => {
    const cardData: CardData[] = [
        {
            heading: 'Stay safe and hide',
            image: Image1,
            description: 'Hide and stay safe from Muslims. Always be in a group and safeguard your family.',
            link: '/article/1'
        },
        {
            heading: 'Become an imposter',
            image: Image2,
            description: 'You need to blend in with their community by wearing a skull cap, speaking their dialect, and agreeing with their beliefs.',
            link: '/article/2'
        },
        // {
        //     heading: 'Card 3',
        //     image: Image3,
        //     description: 'This is a short description for card 3. It summarizes the main points of the card.',
        //     link: '/article/3'
        // }
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '80px' }}>
            {/* Adjust the paddingTop to your needs */}
            {cardData.map((card, index) => (
                <CardComponent
                    key={index}
                    heading={card.heading}
                    image={card.image}
                    description={card.description}
                    link={card.link}
                />
            ))}
        </div>
    );
};


export default CardPage;
