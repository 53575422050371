// CarouselComponent.jsx
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';

// Import images from the assets folder
import image1 from '../assets/poster.jpg';
import image2 from '../assets/poster2.jpg';
import image3 from '../assets/poster3.jpg';

const CarouselComponent = () => {
    const items = [
        {
            src: image1,
            alt: 'Image 1'
        },
        {
            src: image2,
            alt: 'Image 2'
        },
        {
            src: image3,
            alt: 'Image 3'
        }
    ];

    return (
        <Carousel
        interval={2000} // Change images every 2 seconds
        navButtonsAlwaysVisible={true} // Optional: Show navigation buttons at all times
        animation="slide" // Optional: Animation type (slide or fade)
    >
            {items.map((item, index) => (
                <Paper key={index}>
                    <img src={item.src} alt={item.alt} style={{ width: '100%', height: 'auto' }} />
                </Paper>
            ))}
        </Carousel>
    );
};

export default CarouselComponent;
